import axios from "axios";
import userActionTypes from "../actions/types/user.action.type";
import store from "../store/index";
import {
  getErrorMessageFromException,
  getExceptionMessage,
  API_BASE_URL, customErrorDuplicateEmailAddress,
} from "../utils/helper";

export const userQueueService = {
  userUpdatePendingQueue,
  userDeletePendingQueue,
};

function userUpdatePendingQueue(values, setterMethods) {
  return (dispatch) => {
    const loginUserReducer = store.getState()?.loginUserReducer;
    const languageCode = loginUserReducer?.user?.languageCode;
    const token = store.getState()?.loginUserReducer.token;
    const {
      setDialogLoading,
      setShowMessage,
      t,
      pageName,
      handleClickLink,
      setErrorMessage,
    } = setterMethods;

    axios
      .post(
        API_BASE_URL() + "/manage-users/queue/update",
        { ...values, loggedInLanguageCode: languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setDialogLoading) {
          setDialogLoading(false);
        }
        if (setShowMessage) {
          setShowMessage(true);
        }
        if (pageName === "manage-biller" && handleClickLink) {
          handleClickLink();
        } else if (setterMethods?.history) {
          setterMethods?.history.push("/manage-users", {
            fromDetailPage: true,
          });
        }

        dispatch(success(res.data));
      })
      .catch((error) => {
        let errorMessage;
        if (setDialogLoading) {
          setDialogLoading(false);
        }
        if (setShowMessage) {
          setShowMessage(false);
        }
        errorMessage = customErrorDuplicateEmailAddress(error, t);
        if (setErrorMessage) {
          setErrorMessage(errorMessage);
        }
        dispatch(failure(errorMessage));
      });
  };

  function success(data) {
    return { type: userActionTypes.USER_QUEUE_UPDATE_SUCCESS, data };
  }

  function failure(error) {
    return { type: userActionTypes.USER_QUEUE_UPDATE_ERROR, error };
  }
}

function userDeletePendingQueue(values, setterMethods) {
  return (dispatch) => {
    const languageCode = store.getState()?.loginUserReducer?.user?.languageCode;
    const {
      setSaveLoading,
      setOpen,
      setShowMessage,
      t,
      history,
      pageName,
      handleClickLink,
      setErrorMessage,
    } = setterMethods;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        API_BASE_URL() + "/manage-users/queue/delete",
        { ...values, loggedInLanguageCode: languageCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        if (setOpen) {
          setOpen(false);
        }
        if (setShowMessage) {
          setShowMessage(true);
        }
        dispatch(success(res.data));
        if (pageName === "manage-biller" && handleClickLink) {
          handleClickLink();
        } else if (history) {
          history.push("/manage-users");
        }
      })
      .catch((error) => {
        let errorMessage;
        if (setSaveLoading) {
          setSaveLoading(false);
        }
        if (setShowMessage) {
          setShowMessage(false);
        }
        if (setOpen) {
          setOpen(false);
        }
        errorMessage = getExceptionMessage(error, t);
        if (setErrorMessage) {
          setErrorMessage(errorMessage);
        }
        dispatch(failure(errorMessage));
      });
  };

  function success(data) {
    return { type: userActionTypes.USER_QUEUE_DELETE_SUCCESS, data };
  }

  function failure(error) {
    return { type: userActionTypes.USER_QUEUE_DELETE_ERROR, error };
  }
}
