import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import TabPanel from "../custom/containers/TabPanel";
import ChangePassword from "../User/ChangePassword";
import UserProfile from "../User/UserProfile";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import passwordActionTypes from "../../actions/types/password.action.type";
import ERPSBaseTab from "../custom/inputs/ERPSBaseTab";
import { getComponentAccess } from "../../utils/helper";

const AccountSettings = (props) => {
  const { t } = useTranslation();
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const [showMessageForUserProfile, setShowMessageForUserProfile] =
    useState(false);
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  let user = useSelector((state) => state.loginUserReducer.user);
  const hasUserUpdated = useSelector(
    (state) => state.loginUserReducer?.message
  );
  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [hasUserUpdated && showMessageForUserProfile]);
  const {
    location: { state },
  } = props;

  const componentAccessData =
    state?.componentAccess ||
    getComponentAccess(
      {
        function_type_code: "AS",
        label_type_code: "MP",
      },
      loginUser
    );

  return (
    <div role="main">
      {hasUserUpdated && showMessageForUserProfile && (
        <ConfirmationMessage
          message={hasUserUpdated}
          setShowMessage={setShowMessageForUserProfile}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginTop: "1.5rem",
            }}
          >
            <UserProfile
              profileId={user.internalBMOUserId || user.userId}
              pageName="account-settings"
              fiNumber={selectedFiNumber || user.fiNumber}
              setShowMessage={setShowMessageForUserProfile}
              componentAccess={componentAccessData}
              personalUser={true}
              emailHighlight={state.emailHighlight ? true :false}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withSidebar(AccountSettings);
