import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import warningIcon from "../../assets/images/warning-icon.png";
import userActionTypes from "../../actions/types/user.action.type";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";

const ManageBillerNewUser = (props) => {
  const { t } = useTranslation();
  const userRoleTypes = useSelector(
    (state) => state.userRoleTypeReducer?.userRoleTypeList
  );
  const subscribingEntitiesList = useSelector(
    (state) => state.subscribingEntitiesReducer?.subscribingEntitiesList
  );
  const successMessage = useSelector(
    (state) => state.newUserReducer?.newUserAdded
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const duplicateUserIdError = useSelector(
    (state) => state.newUserReducer?.error
  );
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  const billerProfileReducerValue = useSelector(
    (state) => state.billerProfileReducer
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState([]);
  const [subscribeEntityList, setSubscribeEntityList] = useState([]);
  const [userRoleTypeList, setUserRoleTypeList] = useState([]);
  const dispatch = useDispatch();
  const errorRef = useRef();
  const successRef = useRef(null);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error, duplicateUserIdError]);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  useEffect(() => {
    if (subscribingEntitiesList) {
      const data = subscribingEntitiesList.map(
        ({ fiCompanyNumber, fiCompanyName }) => ({
          value: fiCompanyNumber,
          label: fiCompanyName,
        })
      );
      data.unshift({
        value: "",
        label: "",
      });
      setSubscribeEntityList(data);
    }
  }, [subscribingEntitiesList]);

  useEffect(() => {
    if (duplicateUserIdError) {
      dispatch({ type: userActionTypes.NEW_USER_REQUEST });
    }
  }, []);

  useEffect(() => {
    if (userRoleTypes) {
      const data = [
        {
          roleId: "3",
          value: "MA",
          label: t("erps.common.label.wpbilleradmin")?.toUpperCase(),
        },
        {
          roleId: "4",
          value: "MU",
          label: t("erps.common.label.wpbilleruser")?.toUpperCase(),
        },
      ];
      data.unshift({ roleId: "", value: "", label: "" });
      setUserRoleTypeList(data);
    }
  }, [userRoleTypes]);

  const { updateSetState, stateId, componentAccess, parentComponentAccess } =
    props;

  const newBillerUserComponent = componentAccess.find(
    ({ componentType }) => componentType === "BNU"
  );

  const newBillerUserComponentAccess = newBillerUserComponent?.childComponents;

  const languageItems = [
    { name: t("erps.common.label.english"), value: "E" },
    { name: t("erps.common.label.french"), value: "F" },
  ];

  const [values, setValues] = useState({
    userId: "",
    fiNumber: selectedFiNumber || loginUser?.fiNumber,
    roleId: "",
    languageCode: "E",
    fiCompanyNumber: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
    challengeNumber: 0,
    challengeResponse: "",
    changePasswordIndex: "Y",
    logonFailures: 0,
    emailNotification: "N",
    passwordHash: "",
    userIdErrorHighlight: false,
    roleIdErrorHighlight: false,
    subscribingEntityErrorHighlight: false,
    firstNameErrorHighlight: false,
    lastNameErrorHighlight: false,
    emailAddressErrorHighlight: false,
    phoneNumberErrorHighlight: false,
  });

  const handleChange = (prop) => (event) => {
    if (duplicateUserIdError && prop === "userId") {
      dispatch({ type: userActionTypes.NEW_USER_REQUEST, error: null });
    }
    if (prop === "emailNotification") {
      setValues({ ...values, [prop]: event.target.checked ? "Y" : "N" });
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
        ...(prop === "roleId" &&
          billerProfileReducerValue?.billerProfileAssociatedData
            ?.billerNonVolatileDto?.email_notification_ok == "N" && {
            emailNotification: "N",
          }),
        ...(prop === "roleId" &&
          billerProfileReducerValue?.billerProfileAssociatedData
            ?.billerNonVolatileDto?.email_notification_ok == "Y" &&
          event.target.value == "MA" && {
            emailNotification: "Y",
          }),
        ...(prop === "roleId" &&
          billerProfileReducerValue?.billerProfileAssociatedData
            ?.billerNonVolatileDto?.email_notification_ok == "Y" &&
          event.target.value == "MU" && {
            emailNotification: "N",
          }),
      });
    }
  };

  const addUser = (e, setOpenCancelDialog) => {

    dispatch({
      type: userActionTypes.NEW_USER_REQUEST,
      error: null,
      newUserAdded: null,
    });
    const errormsg = validateUser();

    setError(errormsg);

    if (!errormsg.length) {
      setLoader(true);
      setShowSuccessMessage(false);

      const finalObject = createUserFinalObjectForSave();
      const billerId = stateId ? stateId.split("-")[1] || stateId : "";
      dispatch(
        actions.userActions.saveUser(
          { ...finalObject, billerId },
          setValues,
          {
            ...props,
            setLoader,
            loader,
            setShowSuccessMessage,
            t,
            setOpenCancelDialog,
          },
          userRoleTypeList,
          subscribingEntitiesList
        )
      );
    }
  };

  function createUserFinalObjectForSave() {
    const finalObject = { ...values };
    userRoleTypeList.forEach((userType) => {
      if (userType.value === values.roleId) {
        finalObject.roleId = userType.roleId;
      }
    });
    finalObject.actionCode = "A";
    finalObject.statusCode = "A";
    finalObject.loggedInUserId = loginUser.userId;
    return finalObject;
  }

  const validateEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  function validateUser() {
    let errormsg = [];
    var alphaNumericRegEx = /^[0-9A-Za-z]+$/;
    var alphaNumericRegExWithSpace = /^[a-zA-Z0-9.\-' À-ÿ]+$/;
    var numericRegEx = /^[0-9]+$/;
    const obj = {
      roleIdErrorHighlight: false,
      firstNameErrorHighlight: false,
      lastNameErrorHighlight: false,
      emailAddressErrorHighlight: false,
      phoneNumberErrorHighlight: false,
    };

    if (!values.roleId) {
      obj.roleIdErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.roleId.msg", "Please select the Role.")
      );
    }

    if (!values.firstName) {
      obj.firstNameErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.firstname.msg", "First name cannot be blank.")
      );
    }
    if (
      values.firstName &&
      (values.firstName.trim().length < 1 ||
        values.firstName.trim().length > 25)
    ) {
      obj.firstNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.firstname.msg",
          "First name length is between 1 and 25 characters."
        )
      );
    }
    if (
      values.firstName &&
      values.firstName.match(alphaNumericRegExWithSpace) === null
    ) {
      obj.firstNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.firstname.msg",
          "First name must be alpha numeric."
        )
      );
    }

    if (!values.lastName) {
      obj.lastNameErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.lastname.msg", "Last name cannot be blank.")
      );
    }
    if (
      values.lastName &&
      (values.lastName.trim().length < 1 || values.lastName.trim().length > 25)
    ) {
      obj.lastNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.lastname.msg",
          "Last name length is between 1 and 25 characters."
        )
      );
    }
    if (
      values.lastName &&
      values.lastName.match(alphaNumericRegExWithSpace) === null
    ) {
      obj.lastNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.lastname.msg",
          "Last name  must be alpha numeric."
        )
      );
    }
    if (!values.emailAddress) {
      obj.emailAddressErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.blank.emailaddress.msg",
          "Email address cannot be blank."
        )
      );
    }
    if (values.emailAddress && values.emailAddress.length > 80) {
      obj.emailAddressErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.emailaddress.msg",
          "Email address length should not be greater than 60 characters."
        )
      );
    }
    if (values.emailAddress && !validateEmail(values.emailAddress)) {
      obj.emailAddressErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.format.emailaddress.msg",
          "Please enter your e-mail address in this format 'name@example.com'"
        )
      );
    }
    if (values.phone && values.phone.trim().length !== 10) {
      obj.phoneNumberErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.length.phone.msg", "Phone length should be 10.")
      );
    }
    if (values.phone && values.phone.match(numericRegEx) === null) {
      obj.phoneNumberErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.numeric.phone.msg", "Phone Must be numeric.")
      );
    }

    if (values.roleId === "CA" && !values.fiCompanyNumber) {
      errormsg.push(t("erps.manageusers.subscribingentity.msg"));
    }

    setValues((prevState) => ({ ...prevState, ...obj }));

    return errormsg;
  }

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleRedirect = () => {
    if (updateSetState) {
      updateSetState(false);
    }
  };

  return (
    <div>
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.newuser.page.title", "Manage users")}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h2">
            {t("erps.newuser.account.settings", "Account Settings")}
          </Typography>
        </Box>
        {(error.length > 0 || duplicateUserIdError) && (
          <Box
            className="manageUsers__errorMsg__container"
            mt={error.length > 0 || duplicateUserIdError ? 2 : 0}
            role="alert"
          >
            {(error.length > 0 || duplicateUserIdError) && (
              <Box tabIndex={0} ref={errorRef} style={{ display: "flex" }}>
                <Icon className="manageUsers__error__icon" aria-hidden={false}>
                  <img src={warningIcon} alt="Error notification" />
                </Icon>
                <div style={{ marginTop: "-12px" }}>
                  <ul>
                    {" "}
                    <li
                      style={{
                        color: "#C12334",
                        listStyle: "none",
                        marginLeft: "-20px",
                        marginTop: "20px",
                      }}
                    >
                      {t("erps.common.error.title")}
                    </li>
                    {error.map((err) => (
                      <li style={{ color: "#C12334" }}> {err} </li>
                    ))}
                    {duplicateUserIdError && (
                      <li style={{ color: "#C12334" }}>
                        {" "}
                        {duplicateUserIdError}{" "}
                      </li>
                    )}
                  </ul>
                </div>
              </Box>
            )}
          </Box>
        )}

        <Box component="form" autoComplete="off">
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="role"
              type="text"
              error={values.roleIdErrorHighlight}
              select={true}
              label={t("erps.newuser.label.role", "Role")}
              className="three_column__textField"
              value={values.roleId}
              onChange={handleChange("roleId")}
              size="small"
              items={userRoleTypeList}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: values.roleId ? true : false }}
              actionType="hasBillerUserRoleDropDown"
              componentAccess={newBillerUserComponentAccess}
              required={"true"}
            />
            {values.roleId === "CA" && (
              <ERPSBaseTextField
                id="subscribeEntityId"
                type="text"
                select={true}
                label={t(
                  "erps.newuser.label.subscribingentityid",
                  "Subscribing entity id"
                )}
                className="three_column__textField"
                size="small"
                value={values.fiCompanyNumber}
                items={subscribeEntityList}
                onChange={handleChange("fiCompanyNumber")}
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="firstname"
              type="text"
              error={values.firstNameErrorHighlight}
              label={t("erps.newuser.label.firstname", "First name")}
              className="three_column__textField"
              size="small"
              value={values.firstName}
              onChange={handleChange("firstName")}
              required="true"
              actionType="hasBUFNameTextField"
              componentAccess={newBillerUserComponentAccess}
            />
            <ERPSBaseTextField
              id="lastName"
              type="text"
              error={values.lastNameErrorHighlight}
              label={t("erps.newuser.label.lastname", "Last name")}
              className="three_column__textField"
              size="small"
              value={values.lastName}
              onChange={handleChange("lastName")}
              required="true"
              actionType="hasBULNameTextField"
              componentAccess={newBillerUserComponentAccess}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="emailAddress"
              type="text"
              error={values.emailAddressErrorHighlight || duplicateUserIdError === t("erps.manageusers.error.duplicate.emailaddress") }
              label={t("erps.newuser.label.emailaddress", "Email Address")}
              className="three_column__textField"
              size="small"
              value={values.emailAddress}
              onChange={handleChange("emailAddress")}
              actionType="hasBUEmailTextField"
              componentAccess={newBillerUserComponentAccess}
              required={"true"}
            />
            <ERPSBaseTextField
              id="phone"
              type="text"
              error={values.phoneNumberErrorHighlight}
              label={t("erps.newuser.label.phonenumber", "Phone number")}
              className="three_column__textField"
              size="small"
              onChange={handleChange("phone")}
              value={values.phone}
              actionType="hasBUPhoneTextField"
              componentAccess={newBillerUserComponentAccess}
            />
          </Stack>

          <Stack mt={2} direction="row" spacing={3}>
            <ERPSBaseCheckBoxGrp
              name="email_notification"
              value={values?.emailNotification === "Y" ? true : false}
              disabled={
                billerProfileReducerValue?.billerProfileAssociatedData
                  ?.billerNonVolatileDto?.email_notification_ok === "N" &&
                values.roleId == "MU"
                  ? true
                  : false
              }
              label={t(
                "erps.billerregistration.label.sendNotification",
                "Send email notification"
              )}
              onChange={handleChange("emailNotification")}
            />
          </Stack>

          <Grid container>
            <Grid item sm={6} lg={6}>
              <ERPSBaseRadioGroup
                name="language"
                value={values.languageCode}
                label={t("erps.userprofile.language.text")}
                onChange={handleChange("languageCode")}
                items={languageItems}
                actionType="hasBULangDropDown"
                componentAccess={newBillerUserComponentAccess}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.newuser.button.cancel", "Cancel")}
              onClick={handleOpenCancelDialog}
              actionType="hasCancelBillerMUserAccess"
              componentAccess={newBillerUserComponentAccess}
            />
            <ERPSBaseButton
              variant="primary"
              onClick={(e) => addUser(e)}
              className="button_with_margin48"
              label={t("erps.newuser.button.save", "Save")}
              loading={loader}
              actionType="hasSaveBillerMUserAccess"
              componentAccess={newBillerUserComponentAccess}
            />
          </Stack>
        </Box>
      </Box>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </div>
  );
};

export default ManageBillerNewUser;
